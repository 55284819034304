<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            max-width="600px"
            v-on:click:outside="$emit('click:outside')"
        >
            <v-card v-if="action === 'login'">
                <v-card-title>
                    <span class="text-h5">Login</span>
                    <v-spacer/>
                    <a @click="action = 'signup'">Sign Up</a>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.email"
                                    label="Email"
                                    v-model="email"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.password"
                                    type="password"
                                    label="Password"
                                    v-model="password"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="handleLogin">Login</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="action === 'signup'">
                <v-card-title>
                    <span class="text-h5">Sign Up</span>
                    <v-spacer/>
                    <a @click="action = 'login'">Login</a>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form>
                            <v-stepper v-model="step">
                                <v-stepper-header>
                                    <v-stepper-step step="1">
                                        Role
                                    </v-stepper-step>
                                    <v-divider/>
                                    <v-stepper-step v-if="!isFan" step="2">
                                        Price plan
                                    </v-stepper-step>
                                    <v-divider v-if="!isFan"/>
                                    <v-stepper-step :step="infoStep">
                                        User info
                                    </v-stepper-step>
                                </v-stepper-header>
                                <v-stepper-content step="1">
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                :items="roles"
                                                :error-messages="errors.role"
                                                label="Role"
                                                v-model="role"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn :disabled="!role" color="blue" @click="step = isFan ? infoStep : 2">
                                                Continue
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                                <v-stepper-content v-if="!isFan" step="2">
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                :items="pricePlansFiltered"
                                                :error-messages="errors.price_plan"
                                                label="Price plan"
                                                item-text="name"
                                                item-value="id"
                                                v-model="price_plan"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn color="blue" @click="step = 3" :disabled="!price_plan">
                                                Continue
                                            </v-btn>
                                            <v-btn color="red" @click="step = 1">Back</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                                <v-stepper-content :step="infoStep">
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                :error-messages="errors.email"
                                                label="Email"
                                                v-model="email"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                :error-messages="errors.password"
                                                type="password"
                                                label="Password"
                                                v-model="password"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                type="password"
                                                :error-messages="errors.password_confirmation"
                                                label="Password confirmation"
                                                v-model="password_confirmation"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn @click="handleSignUp">Sign Up</v-btn>
                                            <v-btn color="red" @click="step = isFan ? 1 : 2">Back</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-stepper-content>
                            </v-stepper>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import PricePlan from "@/models/PricePlan";

export default {
    name: "LoginSignUpModal",
    data() {
        return {
            dialog: true,
            action: 'login',
            errors: [],
            email: null,
            password: null,
            password_confirmation: '',
            role: null,
            roles: ['fan', 'venue', 'artist'],
            price_plan: null,
            price_plans: [],
            step: 1
        }
    },
    computed: {
        isFan() {
            return this.role === 'fan'
        },
        infoStep() {
            return this.isFan ? 2 : 3
        },
        pricePlansFiltered() {
            return this.price_plans.filter(p => p.name.startsWith(this.role))
        }
    },
    methods: {
        handleLogin() {
            const {email, password} = this;
            this.$auth
                .login({data: {email, password}, redirect: null})
                .then(() => this.$emit('click:outside'))
                .catch((error) => this.errors = error.response.data.errors)
        },
        handleSignUp() {
            const {email, password, password_confirmation, role, price_plan} = this;
            const data = {email, password, password_confirmation, role, price_plan};
            const catchErr = e => {
                this.errors = e.response.data.errors;

                if (this.errors.role) {
                    this.step = 1;
                    return;
                }

                if (this.errors.price_plan) {
                    this.step = 2;
                }
            }

            this.$auth
                .register({data, staySignedIn: true})
                .then(() => {
                    this.$emit('click:outside');
                })
                .catch(catchErr)
        },
    },
    async mounted() {
        this.price_plans = await PricePlan.custom('/price-plans/public').get()
    }
}
</script>

<style scoped>

</style>
