<template>
    <v-select
        v-model="select"
        :items="types"
        v-bind="$attrs"
        :error-messages="errorMessages"
        label="Artist Type"
        item-text="label"
        item-value="value"
    />
</template>

<script>
export default {
    name: "TypeSelect",
    props: {
        value: {
            type: String,
            default: () => null
        },
        errorMessages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            select: null,
            types: [
                {label: 'Artist', value: 'artist'},
                {label: 'Duo', value: 'duo'},
                {label: 'Trio', value: 'trio'},
                {label: 'Full band', value: 'full band'},
            ]
        }
    },
    mounted() {
        if (this.value) {
            this.select = this.value
        }
    },
    watch: {
        select(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>

</style>
