<template>
    <front-layout class="page--artists">
        <v-container>
            <header class="main__header page__top-line">
                <h1>Featured Artists</h1>
                <p>These are the best artists in your area.</p>
            </header>
        </v-container>
        <div class="page__search-form">
            <v-container>
                <v-row class="flex-column flex-sm-row">
                    <v-col>
                        <v-autocomplete
                            v-model="genre"
                            :items="genres"
                            :loading="genreLoading"
                            :search-input.sync="genreQuery"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            item-text="name"
                            item-value="id"
                            label="Genre"
                            prepend-inner-icon="mdi-music-box-multiple-outline"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <city-selector
                            return-object
                            v-model="city"
                            dense solo flat
                            clearable
                            hide-details="auto"
                            prepend-inner-icon="mdi-map-marker"
                            class="rounded-0"
                        />
                    </v-col>
                    <v-col sm="12" md="4">
                        <v-text-field
                            v-model="stageName"
                            clearable
                            hide-details="auto"
                            dense solo flat
                            class="rounded-0"
                            label="Search for Events or Destinations"
                            prepend-inner-icon="mdi-magnify"
                            />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <block slug="ad-1" />
        <v-container class="page__content">
            <v-row v-if="artists.length">
                <v-col v-for="artist in artists" :key="`artist`+artist.id">
                    <artist-card :artist="artist" v-on:showLogin="showLogin = true"/>
                </v-col>
            </v-row>
            <v-row v-else-if="user && !artistsLoading">
                <v-col class="white--text">
                    <p>Didn't find what you were looking for? Add a missing artist and we will contact them.</p>
                    <v-btn @click="showArtistSubmissionModal = true">
                        Add Artist
                    </v-btn>
                </v-col>
                <artist-submission-modal
                    v-if="showArtistSubmissionModal"
                    v-on:click:outside="showArtistSubmissionModal = false"
                />
            </v-row>
            <login-sign-up-modal v-if="showLogin" v-on:click:outside="showLogin = false"/>
        </v-container>
    </front-layout>
</template>

<script>
import Artist from "@/models/Artist";
import Genre from "@/models/Genre";
import FrontLayout from "@/layouts/FrontLayout";
import ArtistCard from "@/components/cards/ArtistCard";
import LoginSignUpModal from "@/components/modal/LoginSignUpModal";
import ArtistSubmissionModal from "@/components/modal/ArtistSubmissionModal";
import CitySelector from "@/components/CitySelector";
import City from "@/models/City";
import Block from "@/components/Block";

export default {
    name: "ArtistsIndex",
    components: {CitySelector, ArtistSubmissionModal, LoginSignUpModal, ArtistCard, FrontLayout, Block},
    data: function () {
        return {
            artistsLoading: false,
            artists: [],
            city: null,
            stageName: null,
            genre: null,
            genres: [],
            genreQuery: '',
            genreLoading: false,
            showLogin: false,
            user: this.$auth.user(),
            showArtistSubmissionModal: false,
        }
    },
    async mounted() {
        this.artistsLoading = true
        await this.setPredefined()
        await this.fetchArtists()
        this.artistsLoading = false
    },
    methods: {
        async fetchArtists() {
            let query = Artist.custom('artists/public').limit(3);

            if (this.city) {
                query = query.where('city_id', this.city.id)
            }

            if (this.stageName) {
                query = query.where('name', this.stageName)
            }

            if (this.genre) {
                query = query.where('genre', this.genre.id)
            }

            this.artists = await query.append('review_score').get()
        },
        async setPredefined() {
            if (this.$route.query.name) {
                this.stageName = this.$route.query.name
            }

            if (this.$route.query.cityId) {
                this.city = await City.find(this.$route.query.cityId)
            }
        }
    },
    watch: {
        city: function (val, oldval) {
            if (val !== oldval) {
                this.fetchArtists()
            }
        },
        stageName: function (val, oldval) {
            if (val !== oldval) {
                this.fetchArtists()
            }
        },

        genre: function (val, oldval) {
            if (val !== oldval) {
                this.fetchArtists()
            }
        },

        genreQuery: async function (val) {
            this.genreLoading = true

            const genres = await Genre.custom('genres/public').where('query', val).limit(10).get();
            if (this.genre) {
                genres.push(this.genre)
            }
            this.genres = genres

            this.genreLoading = false
        },
    }
}
</script>

<style scoped>

</style>
