<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            max-width="600px"
            v-on:click:outside="$emit('click:outside')"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add a artist submission</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.name"
                                    label="Stage name"
                                    v-model="name"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <type-select
                                    v-model="type"
                                    :error-messages="errors.type"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <city-selector
                                    v-model="city_id"
                                    :error-messages="errors.city_id"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="createSubmission">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

</template>

<script>
import TypeSelect from "@/components/artist/TypeSelect";
import ArtistSubmission from "@/models/ArtistSubmission";
import {mapMutations} from "vuex";
import CitySelector from "@/components/CitySelector";


export default {
    name: "ArtistSubmissionModal",
    components: {CitySelector, TypeSelect},
    data() {
        return {
            dialog: true,
            errors: [],
            name: null,
            city_id: null,
            type: null,
        }
    },
    methods: {
        createSubmission() {
            const artistSubmission = new ArtistSubmission()

            artistSubmission.name = this.name
            artistSubmission.city_id = this.city_id
            artistSubmission.type = this.type

            artistSubmission
                .save()
                .then(() => {
                    this.$emit('click:outside');
                    this.showSnackBar({color: 'success', timeout: 3000, text: 'Submission sent'})
                })
                .catch(e => this.errors = e.response.data.errors)
        },
        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style scoped>

</style>
