<template>
    <div class="gjs-ad-block">
        <div v-if="!loading" v-html="html"></div>
        <v-container v-else>
            <v-skeleton-loader type="list-item-avatar" />
        </v-container>
    </div>
</template>

<script>
    import Block from "@/models/Block";

    export default {
        name: "Block",
        props: ['slug'],
        data: function () {
            return {
                html: '',
                css: '',
                loading: false,
            }
        },
        async mounted() {
            this.loading = true;
            const block = await Block.custom(`blocks/slug/${this.slug}`).first();
            this.html = block.html;
            this.css = block.css;
            this.applyCustomStyle()
            this.loading = false;
        },
        beforeDestroy() {
            this.removeCustomStyle()
        },
        methods: {
            applyCustomStyle() {
                const customStyle = document.createElement('style')
                customStyle.type = 'text/css'
                customStyle.id = `block-${this.slug}-stylesheet`
                customStyle.textContent = this.css
                document.querySelector('head').append(customStyle)
            },
            removeCustomStyle() {
                const customStyle = document.querySelector(`#block-${this.slug}-stylesheet`)
                if (customStyle) {
                    customStyle.remove()
                }
            },
        },
    }
</script>
